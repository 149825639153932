import { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { Button, Select } from '@chhjpackages/components';

import { useStyles } from './CancelAppointmentForm.styles';
import { CancelAppointmentFormProps } from './CancelAppointmentForm.types';

export function CancelAppointmentForm({ isDuringRequest, onCancel }: CancelAppointmentFormProps) {
  const styles = useStyles();
  const [reason, setReason] = useState(0);

  const handleSubmit = () => {
    if (reason) {
      onCancel(reason);
    }
  };

  return (
    <form>
      <div className={styles.selectContainer}>
        <Select fullWidth onChange={({ target }) => setReason(target.value as number)} value={reason} size="large">
          <MenuItem value={0} disabled>
            <span className="select-placeholder">– select reason of cancellation –</span>
          </MenuItem>
          {cancelReasons.map(reason => (
            <MenuItem value={reason.id} key={reason.id}>
              {reason.displayName}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          disabled={reason === 0}
          buttonType="twoTone"
          size="large"
          isLoading={isDuringRequest}
          onClick={() => handleSubmit()}
        >
          Cancel appointment
        </Button>
      </div>
    </form>
  );
}

const cancelReasons: Reason[] = [
  { id: 1, displayName: 'I made other arrangements' },
  { id: 28, displayName: 'I am not ready for the appointment' },
  { id: 36, displayName: 'The date I wanted is not available' },
  { id: 30, displayName: 'Service is too expensive' },
  { id: 32, displayName: 'Other' },
];

export type Reason = {
  id: number;
  displayName: string;
};
