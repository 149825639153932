import { SvgIcon, SvgIconProps } from '@material-ui/core';
import { memo } from 'react';

export const HomeIcon = memo(({ color }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 36 36" color={color}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M21.355 20.648h-6.703a1.413 1.413 0 0 0-1.428 1.385v9.685a1.428 1.428 0 0 0 2.855 0V23.414h3.85v8.303a1.416 1.416 0 0 0 2.834 0V22.037A1.393 1.393-2210.378 0 0 21.355 20.648Zm14.167-2.897l-16.568-14.53a1.452 1.452 0 0 0-1.902 0l-16.568 14.53a1.329 1.329 0 0 0-0.39 1.515a1.428 1.428 0 0 0 1.34 0.89h3.547v11.555a1.428 1.428 0 0 0 2.855 0V18.778a1.413 1.413 0 0 0-1.428-1.385h-1.276l12.87-11.284l12.87 11.284h-1.276a1.413 1.413 0 0 0-1.428 1.385v12.94a1.428 1.428 0 0 0 2.855 0V20.159h3.547a1.428 1.428 0 0 0 1.34-0.89A1.363 1.363-2210.378 0 0 35.523 17.748Z"
    ></path>
  </SvgIcon>
));
