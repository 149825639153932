import { colors } from '@chhjpackages/components';
import { makeStyles, Theme } from '@material-ui/core';

type Props = {
  imageWidth: number;
  isShowSignature?: boolean;
};

export const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    height: '100%',
  },
  signatureArea: {
    backgroundColor: colors.functionals.infoLight,
    border: `0.5px solid ${colors.functionals.alert}`,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  signature: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: ({ imageWidth }) => `calc(50% - ${(imageWidth ?? 0) / 2}px)`,
    display: ({ isShowSignature }) => (isShowSignature ? 'block' : 'none'),
  },
}));
