import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    padding: '40px 20px',
    width: '100%',
  },
  titleContainer: {
    position: 'relative',
  },
  title: {
    marginBottom: '12px',
  },
  description: {},
  logoutContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  signatureArea: {
    position: 'absolute',
    width: '38.1%',
    height: '3.35%',
    opacity: 0.5,
  },
});
