import { memo } from 'react';

import { AddressFormattedProps } from './AddressFormatted.types';

export const AddressFormatted = memo(
  ({ location: { id, address, address2, city, state, postal, country } }: AddressFormattedProps) => {
    if (id === 0) {
      return <>-</>;
    }

    return (
      <>
        <span>{[address, address2].filter(Boolean).join(', ')}</span>

        <br />

        <span>{[city, postal, state, country].filter(Boolean).join(', ')}</span>
      </>
    );
  },
);
