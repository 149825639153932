/* eslint-disable no-console */
import { memo } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import { QuotesContextController } from 'context/quotes/quotesContextController/QuotesContextController';
import { Estimate } from 'app/estimate/Estimate';
import { Availability } from 'app/availability/Availability';
import { routePath } from 'utils/routePath/routePath';
import { PrePayment } from 'app/prePayment/PrePayment';
import { AllDoneLayout } from 'app/all-done-layout';

export const Quote = memo(() => {
  const { quoteId } = useParams<{ quoteId: string }>();

  return (
    <QuotesContextController>
      <Switch>
        <Route path={routePath.quoteEstimate()} exact component={Estimate} />
        <Route path={routePath.quoteAvailability()} exact component={Availability} />
        <Route path={routePath.quotePrePayment()} exact component={PrePayment} />
        <Route path={routePath.quoteDone()} exact component={AllDoneLayout} />

        {!!quoteId && <Redirect to={routePath.quoteEstimate()} />}
      </Switch>
    </QuotesContextController>
  );
});
