import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  skeleton: {
    width: '100%',
    height: 'auto',
    aspectRatio: 0.7075,
  },
  pagesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    overflow: 'hidden',
  },
  pagesListLoading: {
    opacity: 0,
  },
}));
