import { memo, useEffect } from 'react';
import { useDialogDispatch } from '@chhjpackages/components';

import { Appointments } from 'app/appointments/Appointments';
import { PageContent } from 'ui/page-content/PageContent';
import { ConfirmAppointmentDialog } from 'app/appointmentManagement/confirmAppointmentComponent/confirmAppointmentDialog/ConfirmAppointmentDialog';

import { useStyles } from './Dashboard.styles';
import { DashboardProps } from './Dashboard.types';

export const Dashboard = memo(
  ({ appointmentId, isConfirmed, beginReview, makePayment, paymentAmount, tipEnabled }: DashboardProps) => {
    const styles = useStyles();
    const setDialog = useDialogDispatch();

    useEffect(() => {
      if (appointmentId || isConfirmed) {
        window.history.pushState(null, '');
      }
    }, [appointmentId, isConfirmed]);

    useEffect(() => {
      if (isConfirmed) {
        setDialog({ open: true, variant: 'schedule', dialogContent: <ConfirmAppointmentDialog /> });
      }
    }, [isConfirmed, setDialog]);

    return (
      <div className={styles.root} data-testid="dashboard">
        <PageContent title="Dashboard" description="Here you can find & manage all your future and past appointments">
          <Appointments
            expandedId={appointmentId}
            beginReview={beginReview}
            makePayment={makePayment}
            paymentAmount={paymentAmount}
            tipEnabled={tipEnabled}
          />
        </PageContent>
      </div>
    );
  },
);
