import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  detailsTitle: {
    textTransform: 'uppercase',
  },
  singleColumn: {
    padding: '20px 16px',
  },
  detailsRow: {
    // TODO: fix for !important
    display: 'table-row!important',
    position: 'relative',
    '& .MuiTableCell-root': {
      borderTopWidth: '0px!important',
      borderBottomWidth: '0px!important',
    },
    '& .MuiTableCell-root > div:not(:first-child)': {
      marginTop: '20px',
    },
  },
  detailsButtonsRow: {},
  alert: {
    width: '600px',
    maxWidth: '80%',
    '& .MuiAlert-message': {
      flex: '1',
      textAlign: 'center',
    },
    '& .MuiAlert-action': {
      paddingLeft: '0',
    },
  },
});
