import { colors } from '@chhjpackages/components';
import { createStyles, Theme, withStyles, makeStyles } from '@material-ui/core';
import { Calendar } from '@material-ui/pickers';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

import { NoahFont, RiftFont } from 'assets/theme/typography';

export const CalendarStyled = withStyles((theme: Theme) => createStyles({}))(Calendar);

export const CalendarOverrides: MuiPickersOverrides = {
  MuiPickersDay: {
    day: {
      fontSize: '0.875rem',
      color: colors.basic.black,
      height: '30px',
      width: '30px',
    },
    daySelected: {},
  },
  MuiPickersCalendarHeader: {
    transitionContainer: {
      '& p': {
        fontFamily: RiftFont,
        fontSize: '1rem',
        fontWeight: 'bold',
        color: colors.secondary.main,
      },
    },
    dayLabel: {
      fontFamily: NoahFont,
      fontSize: '0.625rem',
      fontWeight: 'bold',
      color: colors.secondary.main,
      textTransform: 'uppercase',
      width: '30px',
    },
    iconButton: {
      '& path:first-child': {
        stroke: colors.primary.main,
        strokeWidth: 2,
      },
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      minHeight: '180px',
    },
  },
  MuiPickersDatePickerRoot: {},
};

export const useStyles = makeStyles({
  datePicker: {
    border: `2px solid ${colors.primary.main}`,
    width: 'fit-content',
    margin: '5px',
    overflow: 'hidden',
    padding: '10px',
  },
});
