import { memo, useState } from 'react';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { Button, useDialogDispatch } from '@chhjpackages/components';

import { useAppointmentsDispatch } from 'hooks/useAppointments/useAppointments';
import { useConfirmAppointment } from 'hooks/useConfirmAppointment/useConfirmAppointment';
import { SET_RELOAD_APPOINTMENTS } from 'context/appointments/appointmentsContextReducer/AppointmentsContextReducer.types';

import { ConfirmAppointmentComponentProps } from './ConfirmAppointmentComponent.types';
import { ConfirmAppointmentDialog } from './confirmAppointmentDialog/ConfirmAppointmentDialog';

export const ConfirmAppointmentComponent = memo(({ appointment }: ConfirmAppointmentComponentProps) => {
  const [isDuringRequest, setIsDuringRequest] = useState(false);
  const confirmAppointment = useConfirmAppointment();
  const appointmentsDispatch = useAppointmentsDispatch();
  const setDialog = useDialogDispatch();

  const handleConfirm = async () => {
    setIsDuringRequest(true);

    const isConfirmed = await confirmAppointment(true, appointment.id);
    if (isConfirmed) {
      setDialog({ open: true, variant: 'schedule', dialogContent: <ConfirmAppointmentDialog /> });
      appointmentsDispatch({ type: SET_RELOAD_APPOINTMENTS });
    }
  };

  return (
    <div>
      {!appointment.clientConfirmed ? (
        <Button buttonType="filled" onClick={() => handleConfirm()} isLoading={isDuringRequest}>
          Confirm time
        </Button>
      ) : (
        <CheckCircleOutlineRoundedIcon color="secondary" />
      )}
    </div>
  );
});
