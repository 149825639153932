import { Quote, QuoteLog } from 'api/actions/quotes/QuotesActions.types';
import { QuoteStateType } from '../quotesContext/QuotesContext.types';
import { AppointmentResponseData } from 'api/actions/appointments/AppointmentsActions.types';

export const SET_QUOTE = 'SET_QUOTE';
export const SET_APPOINTMENT = 'SET_APPOINTMENT';
export const SET_QUOTE_LOG = 'SET_QUOTE_LOG';
export const SET_AVAILABILITY = 'SET_AVAILABILITY';
export const SET_PRE_PAYMENT = 'SET_PRE_PAYMENT';
export const SET_LABOR_HOURS = 'SET_LABOR_HOURS';
export const SET_IS_BOOKED = 'SET_IS_BOOKED';
export const SET_ZONE = 'SET_ZONE';

export type SetQuote = typeof SET_QUOTE;
export type SetAppointment = typeof SET_APPOINTMENT;
export type SetQuoteLog = typeof SET_QUOTE_LOG;
export type SetAvailability = typeof SET_AVAILABILITY;
export type SetPrePayment = typeof SET_PRE_PAYMENT;
export type SetLaborHours = typeof SET_LABOR_HOURS;
export type SetIsBooked = typeof SET_IS_BOOKED;
export type SetZone = typeof SET_ZONE;

export type SimpleAction<Type> = {
  type: Type;
};

export type Action<Type, Payload> = SimpleAction<Type> & {
  payload: Payload;
};

export type SetQuoteAction = Action<SetQuote, Quote>;
export type SetAppointmentAction = Action<SetAppointment, AppointmentResponseData | null>;
export type SetQuoteLogAction = Action<SetQuoteLog, QuoteLog>;
export type SetAvailabilityAction = Action<SetAvailability, QuoteStateType['availability']>;
export type SetPrePaymentAction = Action<SetPrePayment, QuoteStateType['prePayment']>;
export type SetLaborHoursAction = Action<SetLaborHours, QuoteStateType['laborHours']>;
export type SetIsBookedAction = Action<SetIsBooked, undefined>;
export type SetZoneAction = Action<SetZone, number>;

export type QuoteContextActions =
  | SetQuoteAction
  | SetAppointmentAction
  | SetQuoteLogAction
  | SetAvailabilityAction
  | SetPrePaymentAction
  | SetLaborHoursAction
  | SetIsBookedAction
  | SetZoneAction;
