import React, { memo } from 'react';
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import clsx from 'clsx';

import { AppointmentCell } from '../appointmentCell/AppointmentCell';
import { useStyles } from '../AppointmentsTableContainer.styles';
import { AppointmentsTableProps } from '../AppointmentsTableContainer.types';
import { AppointmentDetails } from 'app/appointmentDetails/AppointmentDetails';
import { isCategorySupported } from 'utils/isCategorySupported/isCategorySupported';

export const VerticalTable = memo(
  ({ appointments, columns, onExpand, expandedItemId, isFinished, isSingleAppointment }: AppointmentsTableProps) => {
    const styles = useStyles();

    return (
      <>
        {appointments.map(appointment => (
          <TableContainer id={`appointment-${appointment.id}`} key={appointment.id} className={styles.container}>
            <Table size="small">
              <TableBody className={styles.body}>
                {columns.map(column => (
                  <React.Fragment key={column.name}>
                    {column.name === 'expand' && (expandedItemId === appointment.id || isSingleAppointment) && (
                      <AppointmentDetails
                        appointment={appointment}
                        isSingleColumn
                        isFinished={isFinished}
                        showManageButtons={
                          !isFinished && !isSingleAppointment && isCategorySupported(appointment.category.id)
                        }
                      />
                    )}
                    <TableRow>
                      {column.name !== 'expand' && (
                        <TableCell className={styles.headerCell}>{column.displayName}</TableCell>
                      )}
                      <AppointmentCell
                        className={clsx(styles.bodyCell, expandedItemId === appointment.id && styles.iconCellExpanded)}
                        appointment={appointment}
                        column={column.name}
                        onExpand={onExpand}
                      />
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      </>
    );
  },
);
