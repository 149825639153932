import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

import { BarlowFont } from 'assets/theme/typography';
import { theme } from 'assets/theme/Theme';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '15px',
    margin: 'auto',
    height: '70%',
    width: 'auto',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      left: '0',
      right: '0',
      height: '60%',
    },
  },
  toolbarMain: {
    minHeight: '78px',
  },
  toolbarMainContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  toolbarGreen: {
    background: colors.secondary.main,
    minHeight: '35px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '4px',
    },
  },
  barlow: {
    fontFamily: BarlowFont,
    fontSize: '16px',
  },
  bookNow: {
    fontSize: '0.75rem',
  },
});
