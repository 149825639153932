import { Grid, Typography, Hidden } from '@material-ui/core';
import LocalShipping from '@material-ui/icons/LocalShipping';
import { memo } from 'react';

import { useStyles } from './CheckEtaSentDialog.styles';

export const CheckEtaSentDialog = memo(() => {
  const styles = useStyles();

  return (
    <Grid container direction="row" alignItems="center" justify="center" spacing={4}>
      <Grid item>
        <Typography variant="h3" color="secondary">
          Your ETA request has been sent!
        </Typography>
      </Grid>

      <Hidden smDown>
        <Grid item>
          <LocalShipping className={styles.checkIcon} color="secondary" />
        </Grid>
      </Hidden>

      <Grid item>
        <Typography variant="body1" color="secondary" className={styles.dialogDescription}>
          We will be reaching out to you shortly with more information.
        </Typography>
      </Grid>
    </Grid>
  );
});
