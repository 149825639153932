import { SvgIcon } from '@material-ui/core';
import { SvgIconProps, colors } from '@chhjpackages/components';

export const TruckActionIcon = ({ className, fontSize = 128 }: SvgIconProps) => (
  <SvgIcon
    className={className}
    width="128"
    height="128"
    viewBox="0 0 128 128"
    style={{ fill: 'none', fontSize: fontSize }}
    fill="currentColor"
  >
    <g clipPath="url(#clip2)">
      <path
        d="M105.71 46.09H92.93V46.76C92.93 42.13 89.17 38.37 84.54 38.37H27.13C22.5 38.37 18.74 42.13 18.74 46.76V84.72C18.74 89.35 22.5 93.11 27.13 93.11H32.6H32.79C33.75 98.53 38.44 102.61 44.15 102.61C49.87 102.61 54.55 98.54 55.51 93.11H55.96H83.94L84.09 92.87C84.95 98.41 89.69 102.61 95.49 102.61C101.21 102.61 105.89 98.54 106.85 93.11H119.05C121.87 93.11 124.44 90.8 124.44 87.72V64.83C124.45 54.56 116.24 46.09 105.71 46.09Z"
        fill={colors.primary.light}
      />
      <path
        d="M39.24 28.5H13.82C13.13 28.5 12.57 27.94 12.57 27.25C12.57 26.56 13.13 26 13.82 26H39.24C39.93 26 40.49 26.56 40.49 27.25C40.49 27.94 39.93 28.5 39.24 28.5Z"
        fill={colors.secondary.main}
      />
      <path
        d="M75.27 79.82H54.38C53.69 79.82 53.13 79.26 53.13 78.57C53.13 77.88 53.69 77.32 54.38 77.32H75.26C75.95 77.32 76.51 77.88 76.51 78.57C76.51 79.26 75.96 79.82 75.27 79.82Z"
        fill={colors.secondary.main}
      />
      <path
        d="M24.26 79.82H17.02C16.33 79.82 15.77 79.26 15.77 78.57C15.77 77.88 16.33 77.32 17.02 77.32H24.26C24.95 77.32 25.51 77.88 25.51 78.57C25.51 79.26 24.95 79.82 24.26 79.82Z"
        fill={colors.secondary.main}
      />
      <path
        d="M48.32 28.5H44.56C43.87 28.5 43.31 27.94 43.31 27.25C43.31 26.56 43.88 26 44.57 26H48.33C49.02 26 49.58 26.56 49.58 27.25C49.58 27.94 49.02 28.5 48.32 28.5Z"
        fill={colors.secondary.main}
      />
      <path
        d="M10.35 33.53H4.25C3.56 33.53 3 32.97 3 32.28C3 31.59 3.56 31.03 4.25 31.03H10.35C11.04 31.03 11.6 31.59 11.6 32.28C11.6 32.97 11.04 33.53 10.35 33.53Z"
        fill={colors.secondary.main}
      />
      <path
        d="M91.24 59.21C91.24 59.9 91.8 60.46 92.49 60.46H113.61C114.3 60.46 114.86 59.9 114.86 59.21C114.86 58.52 114.3 57.96 113.61 57.96H92.49C91.8 57.96 91.24 58.51 91.24 59.21Z"
        fill={colors.primary.main}
      />
      <path
        d="M100.79 38.75H89.07C88.18 34.35 84.28 31.03 79.62 31.03H22.21C16.89 31.03 12.57 35.35 12.57 40.67V78.63C12.57 83.95 16.89 88.27 22.21 88.27H26.88C28.34 93.73 33.32 97.77 39.23 97.77C45.15 97.77 50.12 93.73 51.58 88.27H78.22C79.68 93.73 84.66 97.77 90.57 97.77C96.49 97.77 101.46 93.73 102.92 88.27H114.12C117.78 88.27 120.76 85.29 120.76 81.63V58.74C120.78 47.72 111.81 38.75 100.79 38.75ZM39.24 95.27C33.56 95.27 28.94 90.65 28.94 84.97C28.94 79.29 33.56 74.67 39.24 74.67C44.92 74.67 49.54 79.29 49.54 84.97C49.54 90.65 44.92 95.27 39.24 95.27ZM52 85.77C52.02 85.5 52.04 85.24 52.04 84.97C52.04 77.91 46.3 72.17 39.24 72.17C32.18 72.17 26.44 77.91 26.44 84.97C26.44 85.24 26.46 85.51 26.48 85.77H22.22C18.28 85.77 15.08 82.57 15.08 78.63V40.67C15.08 36.73 18.28 33.53 22.22 33.53H79.63C83.57 33.53 86.77 36.73 86.77 40.67V72.75C81.57 74.38 77.78 79.24 77.78 84.97C77.78 85.24 77.8 85.51 77.82 85.77H52ZM90.58 95.27C84.9 95.27 80.28 90.65 80.28 84.97C80.28 79.29 84.9 74.67 90.58 74.67C96.26 74.67 100.88 79.29 100.88 84.97C100.88 85.49 100.83 85.99 100.76 86.49C100.68 86.65 100.63 86.83 100.63 87.03C100.63 87.07 100.65 87.1 100.65 87.14C99.65 91.78 95.52 95.27 90.58 95.27ZM118.28 81.63C118.28 84.11 116.14 85.77 114.14 85.77H103.34C103.36 85.5 103.38 85.24 103.38 84.97C103.38 77.91 97.64 72.17 90.58 72.17C90.14 72.17 89.7 72.19 89.26 72.24V41.25H100.79C110.43 41.25 118.28 49.1 118.28 58.74V81.63Z"
        fill={colors.primary.main}
      />
      <path
        d="M42.76 83.72C42.07 83.72 41.51 84.28 41.51 84.97C41.51 86.22 40.49 87.24 39.24 87.24C37.99 87.24 36.97 86.22 36.97 84.97C36.97 83.72 37.99 82.7 39.24 82.7C39.93 82.7 40.49 82.14 40.49 81.45C40.49 80.76 39.93 80.2 39.24 80.2C36.61 80.2 34.47 82.34 34.47 84.97C34.47 87.6 36.61 89.74 39.24 89.74C41.87 89.74 44.01 87.6 44.01 84.97C44.01 84.28 43.45 83.72 42.76 83.72Z"
        fill={colors.primary.main}
      />
      <path
        d="M94.1 83.72C93.41 83.72 92.85 84.28 92.85 84.97C92.85 86.22 91.83 87.24 90.58 87.24C89.33 87.24 88.31 86.22 88.31 84.97C88.31 83.72 89.33 82.7 90.58 82.7C91.27 82.7 91.83 82.14 91.83 81.45C91.83 80.76 91.27 80.2 90.58 80.2C87.95 80.2 85.81 82.34 85.81 84.97C85.81 87.6 87.95 89.74 90.58 89.74C93.21 89.74 95.35 87.6 95.35 84.97C95.35 84.28 94.79 83.72 94.1 83.72Z"
        fill={colors.primary.main}
      />
    </g>
    <defs>
      <clipPath id="clip2">
        <rect width="121.45" height="76.61" fill={colors.basic.white} transform="translate(3 26)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
