import { useParameterizedQuery } from 'react-fetching-library';
import { useCallback } from 'react';

import { GeocodeResponse, GeocodeRequest } from 'api/actions/geocode/GeocodeActions.types';
import { geocode } from 'api/actions/geocode/GeocodeActions';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useMessagesDispatch } from '../useMessages/useMessages';

export function useGeocode() {
  const { accountId, token } = useAuthState();
  const setMessage = useMessagesDispatch();
  const { query: geocodeQuery } = useParameterizedQuery<GeocodeResponse>(geocode);

  return useCallback(
    async (address: string) => {
      if (accountId && token) {
        const request: GeocodeRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: { account_id: accountId },
          query: { addressToGeocode: address, token: token },
        };

        const { error, payload } = await geocodeQuery(request);
        if (!error && payload?.geocoder?.results[0]?.geometry?.location) {
          const loc = payload.geocoder.results[0].geometry.location;
          return { lat: loc.lat, lng: loc.lng };
        }
        if (error && payload?.meta?.status?.description) {
          setMessage({ message: `Error on: GeocodeRequest, ${payload?.meta?.status?.description}`, type: 'warning' });
          return;
        }
      }
    },
    [accountId, geocodeQuery, setMessage, token],
  );
}
