import { Box, Grid, Typography } from '@material-ui/core';
import { Button } from '@chhjpackages/components';

import { ErrorBoundaryFallbackProps } from './ErrorBoundaryFallback.types';

export const ErrorBoundaryFallback = ({ clearError }: ErrorBoundaryFallbackProps) => {
  return (
    <Box display="flex" alignItems="center" height="100%">
      <Grid spacing={3} container alignContent="center" justify="center">
        <Grid item xs={12}>
          <Typography variant="h1" color="secondary" align="center">
            Error
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            <strong>Opps! Something went wrong!</strong>
          </Typography>
          <Typography variant="body1" align="center">
            We are working on the problem. Please try to reload or try again later!
          </Typography>
        </Grid>
        <Grid item>
          <Button buttonType="outlined" onClick={clearError}>
            Reload
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
