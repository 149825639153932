import { forwardRef } from 'react';
import SignaturePad from 'react-signature-canvas';

import { useStyles } from './styles';

export const SignatureCanvas = forwardRef<SignaturePad>((_, ref) => {
  const styles = useStyles();

  return (
    <SignaturePad
      throttle={0}
      minWidth={3}
      maxWidth={3}
      canvasProps={{
        className: styles.canvas,
      }}
      ref={ref}
    />
  );
});
