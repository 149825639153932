import { memo } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { PeriodSelectorProps } from './types';
import { usePeriodSelectorStyles } from './styles';

export const PeriodSelector = memo(({ periods, selected, onSelect }: PeriodSelectorProps) => {
  const styles = usePeriodSelectorStyles();

  return (
    <div className={styles.wrapper}>
      {periods.map(period => (
        <Typography
          key={period}
          variant="h5"
          component="div"
          className={clsx(styles.period, period === selected && styles.selected)}
          onClick={() => onSelect(period)}
        >
          {period}
        </Typography>
      ))}
    </div>
  );
});
