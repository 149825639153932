import { createContext, useState, Dispatch, SetStateAction } from 'react';

import { Message } from 'ui/message/Message';

import { MessagesProviderProps, MessagesContextStateType } from './MessagesContext.types';

export const MessagesContextState = createContext<MessagesContextStateType | undefined>(undefined);
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const MessagesContextDispatch = createContext<Dispatch<SetStateAction<MessagesContextStateType>>>(() => {});

export const MessagesProvider = ({ children }: MessagesProviderProps) => {
  const [messages, setMessages] = useState<MessagesContextStateType>({ message: '', type: undefined });

  return (
    <MessagesContextState.Provider value={messages}>
      <MessagesContextDispatch.Provider value={setMessages}>
        <Message />
        {children}
      </MessagesContextDispatch.Provider>
    </MessagesContextState.Provider>
  );
};
