import { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Loader } from '@chhjpackages/components';

import { MapProps } from './Map.types';
import { styledMap, useStyles } from './Map.styles';

export function Map({ lat, lng }: MapProps) {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const key = 'AIzaSyDjhjz5vvMFbFk0D8B9LGu1yvXHyGf_njo';

  return (
    <div className={styles.mapContainer}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <GoogleMapReact
        bootstrapURLKeys={{ key: key }}
        defaultZoom={14}
        defaultCenter={{ lat: lat, lng: lng }}
        draggable={false}
        yesIWantToUseGoogleMapApiInternals
        options={{
          styles: styledMap,
          fullscreenControl: false,
          disableDefaultUI: true,
          draggable: false,
          draggableCursor: 'auto',
          disableDoubleClickZoom: true,
          gestureHandling: 'none',
          keyboardShortcuts: false,
        }}
        onGoogleApiLoaded={() => setIsLoading(false)}
      ></GoogleMapReact>
    </div>
  );
}
