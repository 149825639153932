import React, { ReactNode, useEffect, useReducer } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { parse } from 'date-fns';

import { appointmentsStateDefault } from '../appointmentsContext/AppointmentsContext';
import { AppointmentsStateContext, AppointmentsDispatchContext } from '../appointmentsContext/AppointmentsContext';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { AppointmentsRequest, AppointmentsResponse } from 'api/actions/appointments/AppointmentsActions.types';
import { fetchAppointments } from 'api/actions/appointments/AppointmentsActions';
import { Appointment } from '../appointmentsContext/AppointmentsContext.types';
import { AppointmentContextReducer } from '../appointmentsContextReducer/AppointmentsContextReducer';
import { SET_APPOINTMENTS } from '../appointmentsContextReducer/AppointmentsContextReducer.types';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';

export function AppointmentsContextController({ children }: { children: ReactNode }) {
  const [state, setState] = useReducer(AppointmentContextReducer, appointmentsStateDefault);
  const throwError = useAsyncError();
  const { accountId, locationId, token, isAuthorized } = useAuthState();
  const { query: getAppointmentsQuery } = useParameterizedQuery<AppointmentsResponse>(fetchAppointments);

  useEffect(() => {
    const getAppointments = async () => {
      if (accountId && locationId && token) {
        const request: AppointmentsRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: { account_id: accountId, location_id: locationId },
          query: { token: token },
        };
        const { error, payload } = await getAppointmentsQuery(request);
        if (!error && !!payload && payload.appointments) {
          const appointments: Appointment[] = payload.appointments.map(appointment => ({
            id: appointment.id,
            category: appointment.category,
            startDate: parse(appointment.start_date, 'yyyy-MM-dd HH:mm:ss', new Date()),
            endDate: parse(appointment.end_date, 'yyyy-MM-dd HH:mm:ss', new Date()),
            type: appointment.type,
            status: appointment.type === 'EST' ? appointment.est_status : appointment.status,
            origin: appointment.origin,
            destination: appointment.destination,
            user: appointment.account,
            readOnly: appointment.read_only,
            hunks: appointment.num_hunks,
            balanceDue: appointment.balance_due,
            tipAmount: appointment.tip_amount,
            squareLocationId: appointment.square_location_id,
            clientConfirmed: appointment.client_confirmed,
            clientReviewed: false,
            assignedHunks: appointment.assigned_hunks,
            reviewSettings: appointment.review_settings,
            hasReviews: appointment.has_reviews,
          }));
          const upcoming = appointments.filter(appointment => !appointment.readOnly);
          const finished = appointments.filter(appointment => appointment.readOnly);
          setState({
            type: SET_APPOINTMENTS,
            payload: {
              appointments: appointments,
              upcomingAppointments: upcoming,
              finishedAppointments: finished,
            },
          });
        } else {
          throwError(`Error on: AppointmentsRequest, ${payload?.meta?.status?.description}`);
        }
      }
    };
    if (isAuthorized && state.reload) {
      getAppointments();
    }
  }, [accountId, getAppointmentsQuery, isAuthorized, locationId, state.reload, throwError, token]);

  return (
    <AppointmentsStateContext.Provider value={state}>
      <AppointmentsDispatchContext.Provider value={setState}>{children}</AppointmentsDispatchContext.Provider>
    </AppointmentsStateContext.Provider>
  );
}
