import { memo } from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from '../AppointmentDetails.styles';
import { AddressFormatted } from '../addressFormatted/AddressFormatted';

import { AppointmentLocationProps } from './AppointmentLocation.types';

export const AppointmentLocation = memo(({ appointment }: AppointmentLocationProps) => {
  const styles = useStyles();

  return (
    <>
      <div>
        <Typography variant="subtitle1" color="secondary" className={styles.detailsTitle}>
          Starting address
        </Typography>

        <Typography variant="body1">
          <AddressFormatted location={appointment.origin} />
        </Typography>
      </div>

      <div>
        <Typography variant="subtitle1" color="secondary" className={styles.detailsTitle}>
          Destination address
        </Typography>

        <Typography variant="body1">
          <AddressFormatted location={appointment.destination} />
        </Typography>
      </div>
    </>
  );
});
