import { memo } from 'react';
import clsx from 'clsx';

import { DialogContentWrapperProps } from './types';
import { useStyles } from './styles';

export const DialogContentWrapper = memo(({ maxWidth, children, classes }: DialogContentWrapperProps) => {
  const styles = useStyles({ maxWidth });

  return (
    <div className={clsx(styles.root, classes?.root)}>
      <div className={clsx(styles.content, classes?.content)}>{children}</div>
    </div>
  );
});
