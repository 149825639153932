import { Grid, Typography, Hidden } from '@material-ui/core';
import { memo } from 'react';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';

import { useStyles } from './ConfirmAppointmentDialog.styles';

export const ConfirmAppointmentDialog = memo(() => {
  const styles = useStyles();

  return (
    <Grid container direction="row" alignItems="center" justify="center" spacing={4}>
      <Grid item>
        <Typography variant="h3" color="secondary" align="center">
          Congratulations! <span className={styles.subtitle}>Your appointment has been confirmed</span>
        </Typography>
      </Grid>

      <Hidden smDown>
        <Grid item>
          <CheckCircleOutlineRoundedIcon className={styles.checkIcon} color="secondary" />
        </Grid>
      </Hidden>

      <Grid item>
        <Typography variant="body1" color="secondary" className={styles.dialogDescription} align="center">
          Welcome to your online account. Here, you can manage your appointments and review your upcoming appointment
          information.
        </Typography>
      </Grid>
    </Grid>
  );
});
