import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    '& .MuiTableCell-root': {
      '&:not(.MuiTableCell-head)': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
      },
      borderBottom: '0px',
    },
  },
  container: {
    // TODO: fix for !important
    position: 'relative',
    overflow: 'visible!important',
    marginTop: '15px!important',
    wordBreak: 'break-word',
  },
  table: {
    minHeight: '150px',
  },
  body: {
    '&::after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      pointerEvents: 'none',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    '& .MuiTableRow-root': {
      verticalAlign: 'top',
    },
  },
  row: {
    cursor: 'pointer',
  },
  headerCell: {
    // TODO: fix for !important
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fontWeight: 'bold!important' as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    textTransform: 'uppercase!important' as any,
    fontSize: '0.875rem!important',
    color: colors.secondary.main,
    padding: '16px 10px',
  },
  bodyCell: {
    // TODO: fix for !important
    fontSize: '1.125rem!important',
    color: `${colors.basic.black}!important`,
    padding: '16px 10px',
    fontWeight: 'bold',
  },

  /* isSingle table */
  single: {
    '& $horizontalTable': {
      '& $body::after': {
        boxShadow: 'none',
      },
      '& .MuiTableCell-root': {
        borderTop: '0px',
      },
    },
    '& $expandIcon': {
      display: 'none',
    },
    '& $row': {
      cursor: 'default',
    },
  },

  /* Finished appointments table  */
  finished: {
    '& $headerCell': {
      color: colors.grey60,
    },
    '& $bodyCell': {
      color: colors.grey60,
      fontWeight: 'normal',
    },
  },

  /* Table without data  */
  emptyIcon: {
    width: '67px',
    height: '64px',
  },
  emptyText: {
    paddingTop: '10px',
    color: colors.grey60,
  },
  noTable: {
    minHeight: '150px',
  },

  /* Appointment Cell - expand  */
  expandIcon: {
    padding: '0',
    width: '100%',
    height: '100%',

    '&:focus': {
      // backgroundColor: `${colors.primary.main}`,
      borderRadius: '0',
    },
  },
  expandIconCell: {
    padding: '10px!important',
  },
  iconCellExpanded: {
    '&$expandIconCell': {
      borderTopWidth: '0px!important',
      borderBottomWidth: '0px!important',
    },
    '& $expandIcon': {
      transform: 'rotate(180deg)',
    },
  },
  horizontalTable: {
    '& $headerCell:first-child': {
      minWidth: '132px',
    },
    '& $expandIconCell': {
      borderTopWidth: '1px!important',
      padding: '16px 10px!important',
    },
  },
});
