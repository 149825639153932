import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    marginBottom: '60px',
  },
  finished: {
    '& $tableTitle': {
      color: colors.grey60,
    },
  },
  tableContainer: {
    marginTop: '60px',
  },
  tableIcon: {
    width: '3.125rem',
    height: 'auto',
    marginRight: '0.875rem',
  },
  tableTitle: {
    display: 'flex',
  },
});
