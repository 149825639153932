import { CreditCardInput, SquarePaymentsForm } from 'react-square-web-payments-sdk';
import * as Square from '@square/web-sdk';
import { useCallback } from 'react';
import { useMediaQuery } from '@material-ui/core';

import { useStyles, creditCardInputStyles } from './SquareForm.styles';
import { SquarePaymentFormProps } from './SquareForm.types';

export const useSquareForm = ({
  paymentAmount,
  onSubmitSquareForm,
  squareLocationId,
  user,
}: SquarePaymentFormProps) => {
  const isSquareMobile = useMediaQuery('(max-width: 607px)', {
    noSsr: true,
  });

  const styles = useStyles({ isSquareMobile });

  const createVerificationDetails = useCallback(() => {
    const verificationDetails: Square.ChargeVerifyBuyerDetails | Square.StoreVerifyBuyerDetails = {
      billingContact: {
        givenName: user.first_name,
        familyName: user.last_name,
        email: user.email,
        countryCode: user.country,
        city: user.city,
        addressLines: [user.address, user.address2],
        postalCode: user.postal,
        phone: user.phone || user.cell,
      },
      amount: paymentAmount.toString(),
      currencyCode: 'USD',
      intent: 'CHARGE',
    };

    return verificationDetails;
  }, [
    paymentAmount,
    user.address,
    user.address2,
    user.cell,
    user.city,
    user.country,
    user.email,
    user.first_name,
    user.last_name,
    user.phone,
    user.postal,
  ]);

  const renderSquarePaymentForm = () => (
    <div className={styles.formContainer}>
      <SquarePaymentsForm
        applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
        locationId={squareLocationId}
        cardTokenizeResponseReceived={onSubmitSquareForm}
        createVerificationDetails={createVerificationDetails}
      >
        <CreditCardInput
          submitButtonId="square-submit-button"
          style={creditCardInputStyles}
          children={({ Button: SqButton }) => (
            <div className={styles.submitButtonContainer}>
              <SqButton />
            </div>
          )}
        />
      </SquarePaymentsForm>
    </div>
  );

  return { renderSquarePaymentForm };
};
