import { Action } from 'react-fetching-library';

import {
  QuoteCalculateRequest,
  QuoteCalculateResponse,
  QuoteLogPutRequest,
  QuoteLogPutResponse,
  QuoteLogRequest,
  QuoteLogResponse,
  QuoteRequest,
  QuoteResponse,
  QuoteUpdateRequest,
} from './QuotesActions.types';

export const fetchQuote = ({
  path: { location_id, account_id, quote_id },
  query: { token },
}: QuoteRequest): Action<QuoteResponse> => ({
  method: 'GET',
  endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/quotes/${quote_id}?token=${token}`,
});

export const updateQuote = ({
  path: { location_id, account_id, quote_id },
  query: { token },
  payload,
}: QuoteUpdateRequest): Action<QuoteResponse> => ({
  method: 'PUT',
  endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/quotes/${quote_id}?token=${token}`,
  body: payload,
});

export const fetchQuoteLog = ({
  path: { account_id, quote_id, log_id },
  query: { token },
}: QuoteLogRequest): Action<QuoteLogResponse> => ({
  method: 'GET',
  endpoint: `/public/website/accounts/${account_id}/quotes/${quote_id}/logs/${log_id}?token=${token}`,
});

export const updateQuoteLog = ({
  path: { account_id, quote_id, log_id },
  query: { token },
  payload,
}: QuoteLogPutRequest): Action<QuoteLogPutResponse> => ({
  method: 'PUT',
  endpoint: `/public/website/accounts/${account_id}/quotes/${quote_id}/logs/${log_id}?token=${token}`,
  body: payload,
});

export const quoteCalculate = ({
  path: { account_id, location_id },
  query: { token },
  payload,
}: QuoteCalculateRequest): Action<QuoteCalculateResponse> => ({
  method: 'POST',
  endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/quotes/calculate?token=${token}&new_booking=true`,
  body: payload,
});
