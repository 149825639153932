import { useState, useEffect, memo, useMemo } from 'react';
import { Typography, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Loader } from '@chhjpackages/components';

import { AppointmentCategory } from 'api/types/appointment';
import { usePriceCheck } from 'hooks/usePriceCheck/usePriceCheck';
import { PricingResponseData } from 'api/actions/pricing/PricingActions.types';
import { QuoteRatesResponseData } from 'api/actions/quoteRates/QuoteRatesActions.types';
import { useQuoteRates } from 'hooks/useQuoteRates/useQuoteRates';
import { DialogContentWrapper } from 'ui/dialog-content-wrapper';

import { PricesProps } from './Prices.types';
import { useStyles } from './Prices.styles';
import { PriceTrucks } from './priceTrucks/PriceTrucks';
import { PriceTable } from './priceTable/PriceTable';
import { PriceQuoteRates } from './priceQuoteRates/PriceQuoteRates';

export const Prices = memo(({ appointment, jobDate, onPricesFetched }: PricesProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const [priceList, setPriceList] = useState<PricingResponseData[]>([]);
  const [quoteRates, setQuoteRates] = useState<QuoteRatesResponseData>();
  const [callRequired, setCallRequired] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getPrices = usePriceCheck();
  const getQuoteRates = useQuoteRates();

  const isLabor = useMemo(() => appointment.category.id === AppointmentCategory.labor, [appointment.category.id]);

  const isJunk = useMemo(() => appointment.category.id === AppointmentCategory.junk, [appointment.category.id]);

  const isExpressJunk = useMemo(() => appointment.category.id === AppointmentCategory.expressJunk, [
    appointment.category.id,
  ]);

  const isMoving = useMemo(() => appointment.category.id === AppointmentCategory.move, [appointment.category.id]);

  const isMoveLabor = useMemo(() => appointment.category.id === AppointmentCategory.moveLabor, [
    appointment.category.id,
  ]);

  useEffect(() => {
    const getPriceData = async () => {
      setIsLoading(true);
      setQuoteRates(undefined);
      setPriceList([]);

      switch (appointment.category.id) {
        case AppointmentCategory.labor:
        case AppointmentCategory.junk:
        case AppointmentCategory.expressJunk:
          setPriceList(await getPrices(appointment, jobDate));

          break;

        case AppointmentCategory.move:
        case AppointmentCategory.moveLabor:
          const rates = await getQuoteRates(appointment, jobDate);

          setQuoteRates(rates);
          setCallRequired(!!rates && rates.call_required);

          break;
      }

      setIsLoading(false);
    };

    getPriceData();
  }, [appointment, getPrices, getQuoteRates, jobDate]);

  useEffect(() => {
    if (!isLoading && onPricesFetched) {
      onPricesFetched({ isCallRequired: callRequired });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callRequired, isLoading]);

  return (
    <>
      {isLoading ? (
        <div style={{ height: '150px' }}>
          <Loader />
        </div>
      ) : !callRequired ? (
        <DialogContentWrapper maxWidth={800}>
          <Grid container justify="center" alignContent="center" direction="column">
            <Grid item xs={12} className={styles.pricesTitleContainer}>
              <Typography variant={isMobile ? 'h6' : 'h4'} color="secondary" className={styles.pricesTitle}>
                See below for{' '}
                <span className={styles.pricesTitleSpan}>{!isMoving && !isMoveLabor && 'example'} price points</span>{' '}
                for our locally owned and operated location servicing your area.
              </Typography>
            </Grid>

            <Grid item xs={12} className={styles.pricesContainer}>
              {(isJunk || isExpressJunk) && <PriceTrucks priceList={priceList} isExpressJunk={isExpressJunk} />}

              {isLabor && <PriceTable priceList={priceList} />}

              {(isMoving || isMoveLabor) && quoteRates && (
                <PriceQuoteRates
                  quoteRates={quoteRates}
                  labels={{ truckAndTravelFee: isMoveLabor ? 'Travel fee' : undefined }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContentWrapper>
      ) : (
        <div className={styles.pricesNotAvailable}>
          <Typography variant="body1" align="center">
            Pricing not available
          </Typography>

          <Typography variant="body1" color="primary" align="center">
            Call us 833-626-1326!
          </Typography>
        </div>
      )}
    </>
  );
});
