import { makeStyles } from '@material-ui/core';

import { theme } from 'assets/theme/Theme';

export const useStyles = makeStyles({
  selectContainer: {
    margin: '60px 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: '25px 0 0',
    },
  },

  helperContainer: {
    position: 'absolute',
    top: '100%',
  },

  formControl: {
    marginBottom: '40px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  buttonContainer: {
    margin: '50px 0',
  },
});
