import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  dateTime: {
    marginBottom: '50px',
  },
  calendarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  swipe: {},
  swipeContainer: {
    position: 'relative',
    justifyContent: 'center',
    marginBottom: '40px',
  },
  swipeItem: {
    minWidth: '0',
  },
  arrow: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none',
    zIndex: 1,
  },
  arrowLeft: {
    left: 0,
  },
  arrowRight: {
    right: 0,
  },
  iconButton: {
    pointerEvents: 'all',
  },
  alert: {
    width: '600px',
    maxWidth: '80%',
    '& .MuiAlert-message': {
      flex: '1',
      textAlign: 'center',
    },
    '& .MuiAlert-action': {
      paddingLeft: '0',
    },
  },
  priceContainer: {
    marginBottom: '50px',
  },
  callRequired: {
    height: '100%',
    margin: '40px 0',
  },

  buttonContainer: {
    margin: '50px 0',
  },
});
