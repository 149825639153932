import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const usePeriodSelectorStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: colors.grey20,
    padding: '8px 16px',
    borderRadius: 30,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
  },

  period: {
    padding: '8px 16px',
    borderRadius: 20,

    '&:hover': {
      color: colors.primary.main,
    },
  },

  selected: {
    color: colors.primary.main,
    backgroundColor: colors.basic.white,
    boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.15)',
  },
}));
