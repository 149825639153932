import { Typography } from '@material-ui/core';
import { Button, useDialogDispatch } from '@chhjpackages/components';
import { memo } from 'react';

import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { Prices } from 'app/prices/Prices';

export const AppointmentPricingButton = memo(({ appointment }: { appointment: Appointment }) => {
  const setDialog = useDialogDispatch();

  const handleOpenDialog = () => {
    setDialog({
      open: true,
      variant: 'schedule',
      dialogContent: <Prices appointment={appointment} jobDate={appointment.startDate} />,
    });
  };

  return (
    <Button buttonType="text" onClick={() => handleOpenDialog()} disableRipple style={{ textTransform: 'initial' }}>
      <Typography variant="body1" color="primary">
        See pricing
      </Typography>
    </Button>
  );
});
