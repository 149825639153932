import { memo } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { displayDate } from 'utils/formatDate/FormatDate';

import { useStyles } from './ReviewAppointmentDialog.styles';
import { ReviewAppointmentDialogProps } from './ReviewAppointmentDialog.types';
import { ReviewAppointmentFace } from './reviewAppointmentFace/ReviewAppointmentFace';

export const ReviewAppointmentDialog = memo(({ appointment }: ReviewAppointmentDialogProps) => {
  const styles = useStyles();

  const arrayToSentence = (a: string[]) =>
    [a.slice(0, -1).join(', '), a[a.length - 1]].filter(w => w !== '').join(' and ');
  let hunks_string = '';
  if (appointment.assignedHunks.length > 0) {
    hunks_string = ' with ' + arrayToSentence(appointment.assignedHunks);
  }

  return (
    <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
      <Grid item>
        <Typography style={{ color: 'grey' }} variant="h5" align="center">
          On <span className={styles.subtitle}>{displayDate(appointment.startDate)}</span>, you had a{' '}
          {appointment.category.name} service {hunks_string}
        </Typography>
        <Typography variant="h4" color="secondary" align="center">
          HOW LIKELY IS IT THAT YOU WOULD RECOMMEND US TO A FRIEND OR COLLEAGUE?
        </Typography>
      </Grid>

      <Grid item>
        <ReviewAppointmentFace appointment={appointment} />
      </Grid>
    </Grid>
  );
});
