import { Box, Grid, Typography } from '@material-ui/core';
import { Button } from '@chhjpackages/components';
import { memo, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageContent } from 'ui/page-content/PageContent';
import { TruckIcon } from 'assets/icons/TruckIcon';
import { PdfViewer } from 'ui/pdf-viewer';
import { SignatureArea } from 'ui/signature-area';
import { useSignPdf } from 'hooks/useSignPdf/useSignPdf';
import { useSignDialog } from 'hooks/useSignDialog/useSignDialog';
import { fileToBase64 } from 'utils/fileToBase64/fileToBase64';
import { routePath } from 'utils/routePath/routePath';
import { useQuotesDispatch, useQuotesState } from 'hooks/useQuotes/useQuotes';
import { SET_QUOTE_LOG } from 'context/quotes/quotesContextReducer/QuotesContextReducer.types';

import { useStyles } from './Estimate.styles';

const signatureFieldOffset = {
  x: 73,
  y: 22,
};

export const Estimate = memo(() => {
  const styles = useStyles();

  const history = useHistory();
  const { quoteId } = useParams<{ quoteId?: string }>();

  const { quote, quoteLog } = useQuotesState();
  const setState = useQuotesDispatch();

  const [isPdfRendering, setIsPdfRendering] = useState(true);

  const {
    pdfRef,
    pdfWidth,
    blobPdf,
    pdfDoc,
    signs,
    countPages,
    pageNumberWithSignature,
    signatureFieldCoordinates,
    getPdfDoc,
    addSign,
    savePdf,
    renderSign,
  } = useSignPdf({
    width: 800,
    currentSignaturePdfField: 'signature_1',
    signatureFieldOffset,
    isLoading: isPdfRendering,
  });

  useEffect(() => {
    if (quoteLog && !pdfDoc) {
      getPdfDoc('data:pdf;base64,' + quoteLog.content);
    }
  }, [getPdfDoc, pdfDoc, quoteLog]);

  const handleSubmitSignDialog = useCallback(
    (signBlob: Blob) => {
      if (!pageNumberWithSignature) {
        return;
      }

      addSign({ blob: signBlob, imageHeight: 21 }, pageNumberWithSignature, {
        ...signatureFieldCoordinates,
        width: {
          type: 'percent',
          value: 20,
        },
      });
    },
    [pageNumberWithSignature, signatureFieldCoordinates, addSign],
  );

  const { handleSignDialogOpen } = useSignDialog({
    title: 'Client signature',
    onSubmit: handleSubmitSignDialog,
  });

  const handleAccept = useCallback(async () => {
    if (quoteLog && !quoteLog?.signed) {
      const newBlobPdf = await savePdf();

      if (!newBlobPdf) {
        throw new Error();
      }

      const content = await fileToBase64(newBlobPdf);

      setState({ type: SET_QUOTE_LOG, payload: { ...quoteLog, signed: true, content } });
    }

    history.replace(routePath.quoteAvailability(Number(quoteId)));
  }, [history, quoteId, quoteLog, savePdf, setState]);

  useEffect(() => {
    if (signs.length) {
      handleAccept();
    }
  }, [quoteLog?.signed, signs.length]);

  const acceptButton = useCallback(
    () => (
      <Box my={3} textAlign="center">
        <Box display="inline-block" width={328}>
          <Button
            buttonType="twoTone"
            fullWidth
            disabled={isPdfRendering || !blobPdf}
            onClick={!quoteLog?.signed && !signs.length ? handleSignDialogOpen : handleAccept}
          >
            Accept and book estimate
          </Button>
        </Box>
      </Box>
    ),
    [blobPdf, handleAccept, handleSignDialogOpen, isPdfRendering, quoteLog?.signed, signs.length],
  );

  return (
    <div className={styles.root} data-testid="estimate">
      <PageContent
        title="Estimate"
        description={
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Box display="flex" alignItems="center">
                <TruckIcon fontSize={48} />
              </Box>
            </Grid>

            <Grid item>
              <Typography variant="h4" color="secondary">
                {quote?.type.id === 1 ? 'Stress-free' : 'Inventory'} move estimate
              </Typography>
            </Grid>
          </Grid>
        }
        variant="secondary"
      >
        {acceptButton()}

        <Box my={3}>
          <PdfViewer
            ref={pdfRef}
            width={pdfWidth}
            pagesCount={countPages || 1}
            loading={isPdfRendering}
            documentProps={{
              file: blobPdf,
            }}
            pageProps={{
              onRenderSuccess: () => {
                setIsPdfRendering(false);
              },
            }}
            pageContent={pageNumber => {
              const shouldRenderSignatureArea =
                pageNumber === pageNumberWithSignature &&
                signatureFieldCoordinates.x !== 0 &&
                signatureFieldCoordinates.y !== 0;

              const signatureAreaElement = shouldRenderSignatureArea ? (
                <SignatureArea
                  classes={{ root: styles.signatureArea }}
                  style={{
                    left: `${signatureFieldCoordinates.x}px`,
                    top: `${signatureFieldCoordinates.y}px`,
                  }}
                  onClick={handleSignDialogOpen}
                />
              ) : (
                <></>
              );

              const signElements = signs.map(sign => (sign.pageNumber === pageNumber ? renderSign(sign) : <></>));

              return (
                <>
                  {!quoteLog?.signed && !signs.length && signatureAreaElement}
                  {signElements}
                </>
              );
            }}
          />
        </Box>

        {acceptButton()}
      </PageContent>
    </div>
  );
});
