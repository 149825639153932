import { ReactNode, useEffect, useReducer } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { fetchLocation } from 'api/actions/locations/LocationsActions';
import { LocationResponse, LocationsRequest } from 'api/actions/locations/LocationsActions.types';
import { LocationContextReducer } from '../locationContextReducer/LocationContextReducer';
import { LocationContext, locationStateDefault } from '../locationContext/LocationContext';
import { SET_LOCATION } from '../locationContextReducer/LocationContextReducer.types';

export const LocationContextController = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useReducer(LocationContextReducer, locationStateDefault);

  const { accountId, token, locationId, isAuthorized } = useAuthState();
  const throwError = useAsyncError();

  const { query: getLocationQuery } = useParameterizedQuery<LocationResponse>(fetchLocation);

  useEffect(() => {
    const getLocation = async () => {
      if (token && accountId && locationId) {
        const request: LocationsRequest = {
          path: { location_id: locationId, account_id: accountId },
          query: { token: token },
        };

        const { error, payload } = await getLocationQuery(request);

        if (!error && !!payload && payload.locations && payload.locations.length) {
          const payloadLocation = payload.locations[0];
          setState({
            type: SET_LOCATION,
            payload: {
              location: {
                ...payloadLocation,
                paymentVendor: payloadLocation.payment_vendor,
              },
            },
          });
        } else {
          throwError(`Error on: LocationRequest, ${payload?.meta?.status?.description}`);
        }
      }
    };

    if (isAuthorized) {
      getLocation();
    }
  }, [isAuthorized, token, accountId, locationId, throwError, getLocationQuery]);

  return <LocationContext.Provider value={state}>{children}</LocationContext.Provider>;
};
