import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

import minimumTruck from 'assets/images/minimum-truck.png';
import halfTruck from 'assets/images/half-truck.png';
import fullTruck from 'assets/images/full-truck.png';

export const useStyles = makeStyles({
  root: {},
  trucksContainer: {
    textAlign: 'center',
  },
  truck: {
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '120px',
    height: '100px',
    margin: 'auto',
  },
  minimumTruck: {
    backgroundImage: `url(${minimumTruck})`,
  },
  halfTruck: {
    backgroundImage: `url(${halfTruck})`,
  },
  fullTruck: {
    backgroundImage: `url(${fullTruck})`,
  },
  loadTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notAvailable: {
    color: colors.basic.black,
    fontWeight: 'normal',
  },
});
