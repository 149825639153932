import { SvgIcon } from '@material-ui/core';
import { memo } from 'react';

export const PriceIcon = memo(({ className, fontSize }: { className?: string; fontSize?: number }) => (
  <SvgIcon
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    style={{ fill: 'none', fontSize }}
  >
    <path
      d="M36.7503 44.1781V22.3147V6.00829C36.7503 4.91512 35.8393 4.00415 34.7461 4.00415H14.6136C13.5205 4.00415 12.6095 4.91512 12.6095 6.00829V22.3147V44.1781V45.7267L13.1561 45.9089C13.3383 46 13.5205 46 13.7027 45.9089L15.7068 44.9979L17.6198 45.9089C17.802 46 17.9842 46 18.1664 45.9089L20.1706 44.9979L22.1747 45.9089C22.3569 46 22.5391 46 22.7213 45.9089L24.6343 44.9979L26.6385 45.9089C26.8207 46 27.0029 46 27.1851 45.9089L29.1892 44.9979L31.1933 45.9089C31.2844 45.9089 31.3755 46 31.4666 46C31.5577 46 31.6488 46 31.7399 45.9089L33.7441 44.9979L35.7482 45.9089C35.9304 46 36.1126 46 36.2948 45.9089L36.6592 45.7267V44.1781H36.7503Z"
      fill="#F0CBA7"
    />
    <path
      d="M32.7419 26.9607V42.0828"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.4397 2H31.4666C32.1954 2 32.742 2.54658 32.742 3.27536V11.9296"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0587 41.9917V3.27536C10.0587 2.54658 10.6053 2 11.3341 2H14.7047"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.1581 9.0145H28.5515"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.0732 13.4783H23.8144"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.1581 21.3126H22.7212"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.1581 25.8675H22.7212"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.6384 25.8675H28.5515"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.1581 30.3313H22.7212"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.6384 30.3313H28.5515"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.6384 36.5259H28.5515"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.742 23.8634C36.0215 23.8634 38.6633 21.2215 38.6633 17.942C38.6633 14.6625 36.0215 12.0207 32.742 12.0207C29.4625 12.0207 26.8207 14.6625 26.8207 17.942C26.8207 21.1304 29.4625 23.8634 32.742 23.8634Z"
      fill="#E9F0EB"
    />
    <path
      d="M14.1581 36.5259H16.1622"
      stroke="#E97211"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.2845 19.6729C31.6489 20.1284 32.1955 20.4017 32.742 20.4017C33.653 20.4017 34.3818 19.8551 34.3818 19.2174C34.3818 18.5797 33.653 18.0332 32.742 18.0332C31.8311 18.0332 31.1023 17.4866 31.1023 16.8489C31.1023 16.2112 31.8311 15.6646 32.742 15.6646C33.2886 15.6646 33.8352 15.9379 34.1996 16.3934"
      stroke="#206A37"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.7421 20.3106V21.1305"
      stroke="#206A37"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.7421 14.6626V15.4824"
      stroke="#206A37"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.742 23.8634C36.0215 23.8634 38.6633 21.2215 38.6633 17.942C38.6633 14.6625 36.0215 12.0207 32.742 12.0207C29.4625 12.0207 26.8207 14.6625 26.8207 17.942C26.8207 21.1304 29.4625 23.8634 32.742 23.8634Z"
      stroke="#206A37"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.4707 41.4451L32.742 41.8095L30.7378 40.8986C30.5556 40.8075 30.3734 40.8075 30.1912 40.8986L28.1871 41.8095L26.183 40.8986C26.0008 40.8075 25.8186 40.8075 25.6364 40.8986L23.6322 41.8095L21.6281 40.8986C21.4459 40.8075 21.2637 40.8075 21.0815 40.8986L19.1685 41.8095L17.1643 40.8986C16.9821 40.8075 16.7999 40.8075 16.6177 40.8986L14.6136 41.8095L12.7006 40.8986C12.5184 40.8075 12.3362 40.8075 12.154 40.8986L10.1498 41.8095L9.32996 41.354V42.9027L9.87654 43.176C10.0587 43.2671 10.2409 43.2671 10.4231 43.176L12.4273 42.265L14.3403 43.176C14.5225 43.2671 14.7047 43.2671 14.8869 43.176L16.891 42.265L18.8952 43.176C19.0774 43.2671 19.2596 43.2671 19.4418 43.176L21.3548 42.265L23.3589 43.176C23.5411 43.2671 23.7233 43.2671 23.9055 43.176L25.9097 42.265L27.9138 43.176C28.096 43.2671 28.2782 43.2671 28.4604 43.176L30.4645 42.265L32.4687 43.176C32.5598 43.176 32.6509 43.2671 32.742 43.2671C32.8331 43.2671 32.9242 43.2671 33.0153 43.176L33.3796 42.9938V41.4451H33.4707Z"
      fill="#E97211"
    />
  </SvgIcon>
));
