import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  dialogContainer: {
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  subtitle: {
    color: colors.primary.main,
  },
  dialogHeader: {
    width: '100%',
  },
  feedbackInput: {
    width: '100%',
  },
});
