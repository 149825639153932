import { Theme, makeStyles } from '@material-ui/core';

export const useAllDoneLayoutStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100dvh - 120px)',
    width: '100%',
    position: 'relative',
    padding: '48px 0',
  },

  lottieBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 16,
    width: 'calc(100% - 48px)',
    maxWidth: 720,
    position: 'relative',
    zIndex: 1,
  },

  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 24,
    gap: 24,

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));
