import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { useCallback, useState } from 'react';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { useQuotesState } from 'hooks/useQuotes/useQuotes';
import { fetchArrivalWindows } from 'api/actions/arrivalWindows/ArrivalWindowsActions';
import {
  ArrivalWindow,
  ArrivalWindowsRequest,
  ArrivalWindowsResponse,
} from 'api/actions/arrivalWindows/ArrivalWindowsActions.types';
import {
  AvailabilityRequest,
  AvailabilityResponse,
  AvailabilityResponseData,
} from 'api/actions/availability/AvailabilityActions.types';
import { checkAvailability } from 'api/actions/availability/AvailabilityActions';
import { dispatchMessages } from 'api/actions/dispatchMessages/DispatchMessages';
import { DispatchMessagesRequest } from 'api/actions/dispatchMessages/DispatchMessages.types';

export function useArrivalWindows() {
  const { accountId, locationId, token } = useAuthState();
  const throwError = useAsyncError();

  const { quote, laborHours, zone } = useQuotesState();
  const [loading, setLoading] = useState(true);

  // dispatch messages
  const { mutate: dispatchMessagesQuery } = useMutation(dispatchMessages);
  const sendDispatchMessage = useCallback(async () => {
    if (token && locationId && accountId) {
      const request: DispatchMessagesRequest = {
        path: { account_id: accountId, location_id: locationId },
        query: { token },
        payload: { type: 'no_available_times' },
      };

      const { error, payload } = await dispatchMessagesQuery(request);

      if (error) {
        throwError(`Error on: QuoteRatesRequest, ${payload?.meta?.status?.description}`);
      }

      setLoading(false);
    }
  }, [accountId, dispatchMessagesQuery, locationId, throwError, token]);

  const { query: checkAvailabilityQuery } = useParameterizedQuery<AvailabilityResponse>(checkAvailability);
  const [availabilities, setAvailabilities] = useState<AvailabilityResponseData[]>([]);

  const doCheckAvailability = useCallback(async () => {
    if (locationId && token && quote) {
      const request: AvailabilityRequest = {
        path: { location_id: locationId },
        query: { token: token },
        payload: {
          type: 'JOB',
          category_id: quote.category.id,
          view_date: quote.date,
          duration: quote.type.id === 1 ? laborHours.high : laborHours.exact,
          number_of_days: 1,
          zone_id: zone.toString(),
        },
      };
      const { error, payload } = await checkAvailabilityQuery(request);

      if (!error && payload && payload.availability) {
        if (payload.availability.length) {
          setAvailabilities(payload.availability);
          setLoading(false);
          return payload.availability;
        } else {
          sendDispatchMessage();
        }
      } else {
        throwError(`Error on: AvailabilityRequest, ${payload?.meta?.status?.description}`);
      }
    }
  }, [
    checkAvailabilityQuery,
    laborHours.exact,
    laborHours.high,
    locationId,
    quote,
    sendDispatchMessage,
    throwError,
    token,
    zone,
  ]);

  const { query: arrivalWindowsQuery } = useParameterizedQuery<ArrivalWindowsResponse>(fetchArrivalWindows);
  const [arrivalWindows, setArrivalWindows] = useState<ArrivalWindow[]>([]);
  const getArrivalWindows = useCallback(async () => {
    if (token && locationId && quote) {
      const request: ArrivalWindowsRequest = {
        location_id: locationId,
        token,
      };

      const { error, payload } = await arrivalWindowsQuery(request);

      if (!error && payload?.arrivalwindows.length) {
        const windows = payload.arrivalwindows.filter(item => item.category.id === quote.category.id);

        setArrivalWindows(windows);

        doCheckAvailability();
      } else {
        throwError(`Error on: QuoteRatesRequest, ${payload?.meta?.status?.description}`);
      }
    }
  }, [arrivalWindowsQuery, doCheckAvailability, locationId, quote, throwError, token]);

  return { arrivalWindows, availabilities, loading, getArrivalWindows, doCheckAvailability };
}
