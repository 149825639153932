import { createContext } from 'react';

import { QuoteDispatchType, QuoteStateType } from './QuotesContext.types';

export const quoteStateDefault: QuoteStateType = {
  quote: null,
  quoteLog: null,
  appointment: null,
  isLoading: true,
  availability: undefined,
  prePayment: undefined,
  laborHours: {
    exact: 2,
    high: 2,
  },
  isBooked: false,
  zone: 1,
};

export const QuoteStateContext = createContext<QuoteStateType | undefined>(undefined);
export const QuoteDispatchContext = createContext<QuoteDispatchType | undefined>(undefined);
