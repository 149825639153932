import { QuoteStateType } from '../quotesContext/QuotesContext.types';

import {
  QuoteContextActions,
  SET_APPOINTMENT,
  SET_AVAILABILITY,
  SET_IS_BOOKED,
  SET_LABOR_HOURS,
  SET_PRE_PAYMENT,
  SET_QUOTE,
  SET_QUOTE_LOG,
  SET_ZONE,
} from './QuotesContextReducer.types';

export function QuoteContextReducer(state: QuoteStateType, action: QuoteContextActions): QuoteStateType {
  switch (action.type) {
    case SET_QUOTE:
      return {
        ...state,
        quote: action.payload,
      };

    case SET_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
      };

    case SET_QUOTE_LOG:
      return {
        ...state,
        quoteLog: action.payload,
        isLoading: false,
      };

    case SET_AVAILABILITY:
      return {
        ...state,
        availability: action.payload,
      };

    case SET_PRE_PAYMENT:
      return {
        ...state,
        prePayment: action.payload,
      };

    case SET_LABOR_HOURS:
      return {
        ...state,
        laborHours: action.payload,
      };

    case SET_IS_BOOKED:
      return {
        ...state,
        isBooked: true,
      };

    case SET_ZONE:
      return {
        ...state,
        zone: action.payload,
      };

    default:
      throw new Error();
  }
}
