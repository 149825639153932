import { memo } from 'react';
import { AppBar, Toolbar, Typography, Grid } from '@material-ui/core';
import { Button } from '@chhjpackages/components';

import { HomeIcon } from 'assets/icons/HomeIcon';
import { Image } from 'ui/image/Image';
import logo from 'assets/images/logo.png';
import { AppRoute } from 'routing/AppRoute.enum';

import { useStyles } from './Header.styles';

export const Header = memo(() => {
  const styles = useStyles();

  return (
    <AppBar className={styles.root} position="static" color="transparent" elevation={0} data-testid="header">
      <Image src={logo} className={styles.logo} alt="College H.U.N.K.S. logo" />
      <Toolbar className={styles.toolbarMain}>
        <Grid className={styles.toolbarMainContainer} container spacing={2} alignItems="center">
          <Grid item>
            <Button buttonType="text" href={AppRoute.chhj} startIcon={<HomeIcon color="secondary" />}>
              Home
            </Button>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="subtitle1" color="primary">
                  833-626-1326
                </Typography>
              </Grid>
              <Grid item>
                <Button buttonType="filled" fullWidth href={AppRoute.booking} size="small">
                  Book now
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      <Toolbar className={styles.toolbarGreen} variant="dense"></Toolbar>
    </AppBar>
  );
});
