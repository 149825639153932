import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  subtitle: {
    color: colors.primary.main,
  },
  checkIcon: {
    fontSize: '6rem',
    filter: `drop-shadow(5px 4px 0px ${colors.secondary.main}55)`,
  },
  dialogDescription: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
}));
