/* eslint-disable no-console */
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Button, useAlert } from '@chhjpackages/components';
import { Redirect, useHistory } from 'react-router-dom';

import { PageContent } from 'ui/page-content/PageContent';
import { useQuotesDispatch, useQuotesState } from 'hooks/useQuotes/useQuotes';
import { useArrivalWindows } from 'hooks/useArrivalWindows/useArrivalWindows';
import { AvailableHours } from 'ui/availableHours';
import { AvailabilityResponseData } from 'api/actions/availability/AvailabilityActions.types';
import { NoteBadge } from 'ui/note-badge';
import { PriceIcon } from 'assets/icons/PriceIcon';
import { routePath } from 'utils/routePath/routePath';
import { SET_AVAILABILITY } from 'context/quotes/quotesContextReducer/QuotesContextReducer.types';
import { AppRoute } from 'routing/AppRoute.enum';
import { useCreateAppointment } from 'hooks/useCreateAppointment/useCreateAppointment';
import { ProcessSpinner } from 'ui/process-spinner';

import { useStyles } from './Availability.styles';

export const Availability = memo(() => {
  const history = useHistory();
  const { showAlert } = useAlert();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const styles = useStyles();

  const { quote, quoteLog, availability, prePayment, isLoading: appLoading, zone } = useQuotesState();
  const setState = useQuotesDispatch();
  const { arrivalWindows, availabilities, loading, getArrivalWindows, doCheckAvailability } = useArrivalWindows();

  useEffect(() => {
    if (!appLoading) {
      getArrivalWindows();
    }
  }, [appLoading, zone]);

  const handleSlotSelect = useCallback(
    (slot: AvailabilityResponseData) => {
      setState({
        type: SET_AVAILABILITY,
        payload: {
          end: slot.schedule.end,
          resourceId: slot.resource.id,
          start: slot.schedule.start,
        },
      });
    },
    [setState],
  );

  const paymentAvailable = useMemo(() => prePayment?.collect_online && prePayment?.amount, [
    prePayment?.amount,
    prePayment?.collect_online,
  ]);
  const { makeAppointment } = useCreateAppointment();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleNext = useCallback(async () => {
    if (quote?.id) {
      if (!paymentAvailable) {
        setSubmitting(true);
        makeAppointment()
          .then(appointment => {
            if (appointment?.id) {
              history.replace(routePath.quoteDone(quote.id));
            } else {
              doCheckAvailability().then(() => {
                setSubmitting(false);
              });
            }
          })
          .catch(() => {
            setSubmitting(false);
          });
      } else {
        history.replace(routePath.quotePrePayment(quote.id));
      }
    }
  }, [history, makeAppointment, paymentAvailable, quote?.id]);

  if (!appLoading && !loading && !quote?.id) {
    showAlert("Estimate is not provided or doesn't exist.", {
      variant: 'error',
      autoHideDuration: null,
    });
    return <Redirect to={AppRoute.dashboard} />;
  }

  if (!quote?.id) {
    return <></>;
  }

  if (!appLoading && !quoteLog?.signed) {
    showAlert('Please sign your estimate first.', {
      variant: 'error',
      autoHideDuration: null,
    });
    return <Redirect to={routePath.quoteEstimate(quote.id)} />;
  }

  if (!paymentAvailable && isSubmitting) {
    return <ProcessSpinner />;
  }

  return (
    <div className={styles.root} data-testid="estimate">
      <PageContent title={`Book your ${format(new Date(quote.date), 'MM/dd/yyyy')} move`} variant="secondary">
        <Box my={3} mx={{ xs: 0, md: 4 }}>
          {loading || availabilities.length ? (
            <>
              <AvailableHours
                items={availabilities}
                loading={loading}
                arrivalWindows={arrivalWindows}
                selected={availability}
                onSelect={handleSlotSelect}
              />

              <Box mt={3} mb={{ xs: 3, md: 6 }}>
                <NoteBadge
                  icon={<PriceIcon fontSize={48} />}
                  text="Prices vary throughout the month. To change your date please contact your location for a new estimate to be sent to reflect accurate pricing for that date."
                />
              </Box>
            </>
          ) : (
            <Box py={10}>
              <Typography variant="h3" align="center" color="primary">
                You’ll hear from us soon!
              </Typography>
              <Typography align="center">
                Your local College HUNKS will reach out to you soon to discuss your details.
              </Typography>
            </Box>
          )}

          <Grid container alignItems="center" justify="center" spacing={isTablet ? 2 : 5}>
            <Grid item xs={12} sm={6} md={5} style={{ order: isMobile ? 2 : 1 }}>
              <Button
                buttonType="outlined"
                fullWidth
                onClick={() => history.replace(routePath.quoteEstimate(quote.id))}
              >
                Back
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={5} style={{ order: isMobile ? 1 : 2 }}>
              <Button
                buttonType="twoTone"
                fullWidth
                disabled={!availabilities.length || !availability}
                onClick={handleNext}
                isLoading={isSubmitting}
              >
                {!prePayment?.collect_online || !prePayment.amount ? 'Submit' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </PageContent>
    </div>
  );
});
