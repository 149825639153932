type Config = {
  behavior?: ScrollBehavior;
  timeout?: number;
  offsetX?: number;
  offsetY?: number;
};
export const scroll = (config?: Config) => {
  const { behavior = 'smooth', timeout = 800, offsetX = 0, offsetY = 0 } = config || {};

  setTimeout(() => {
    window?.scroll({ left: offsetX, top: offsetY, behavior });
  }, timeout);
};
