import { memo, useEffect } from 'react';
import { Link, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Lottie from 'react-lottie';
import { Redirect } from 'react-router-dom';
import { useAlert } from '@chhjpackages/components';

import { ReactComponent as ClapImg } from 'assets/images/clap.svg';
import { useQuotesState } from 'hooks/useQuotes/useQuotes';
import { AppRoute } from 'routing/AppRoute.enum';
import { useAuthState } from 'hooks/useAuthState/useAuthState';

import { useAllDoneLayoutStyles } from './styles';
import { defaultOptions } from './utils';

export const AllDoneLayout = memo(() => {
  const { showAlert } = useAlert();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const styles = useAllDoneLayoutStyles();

  const { accountId, locationId, token } = useAuthState();
  const { quote } = useQuotesState();

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('quote-estimate');
    };
  }, []);

  if (!quote?.job.id) {
    showAlert('Appointment is not created yet.', {
      variant: 'error',
      autoHideDuration: null,
    });

    return <Redirect to={AppRoute.dashboard} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.lottieBox}>
        <Lottie options={defaultOptions} height="100%" width="100%" />
      </div>

      <div className={styles.content}>
        <ClapImg />

        <Typography variant={isMobile ? 'h3' : 'h2'} component="div" align="center">
          Congratulations!
          <br />
          You’ll hear from us soon.
        </Typography>
        <Typography component="div" align="center">
          Your local College HUNKS will reach out to you prior to your scheduled date to confirm your appointment and
          details.
          <br />
          <br />
          In the meantime you will receive a confirmation email at:
        </Typography>
        <Typography color="secondary" component="div" align="center">
          <b>{quote.account.email}</b>
        </Typography>
        <Typography component="div" align="center">
          If your plans have changed, you can reschedule the appointment{' '}
          <Link
            href={
              AppRoute.login +
              `?location_id=${locationId}&account_id=${accountId}&token=${token}&?appointment_id=${quote?.job.id}`
            }
            color="secondary"
            underline="always"
          >
            here
          </Link>
        </Typography>
      </div>
    </div>
  );
});
