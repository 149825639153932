import qs from 'qs';

import { MagicLinkParams } from './LoginController.types';

export const getUrlParams = (search: string): MagicLinkParams | undefined => {
  const urlParams = qs.parse(search, { ignoreQueryPrefix: true }) as Record<string, string>;

  if (!urlParams || !urlParams.account_id || !urlParams.location_id || !urlParams.token) {
    return;
  }

  return {
    accountId: parseInt(urlParams.account_id),
    appointmentId: urlParams.appointment_id ? parseInt(urlParams.appointment_id) : undefined,
    beginReview: urlParams.action === 'review_appointment',
    confirmAppointment: urlParams.action === 'confirm_appointment',
    locationId: parseInt(urlParams.location_id),
    makePayment: urlParams.action === 'make_payment',
    paymentAmount: parseFloat(urlParams.payment_amount) || 0,
    quoteId: urlParams.quote_id ? parseInt(urlParams.quote_id) : undefined,
    signEstimate: urlParams.action === 'sign_estimate',
    tipEnabled: urlParams.tip_enabled === '0' ? false : true,
    token: urlParams.token,
  };
};
