import { memo } from 'react';
import { Skeleton } from '@material-ui/lab';

import { useTimeSlotStyles } from './styles';

export const TimeSlotSkeleton = memo(() => {
  const styles = useTimeSlotStyles();

  return (
    <div className={styles.hourBox}>
      <Skeleton animation="wave" variant="rect" width="100%" height="41px" />
    </div>
  );
});
