import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { useNodeBadgeStyles } from './styles';
import { NoteBadgeProps } from './types';

export const NoteBadge = ({ icon, text, hideIconOnMobile, subText, id }: NoteBadgeProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const styles = useNodeBadgeStyles();

  return (
    <>
      <div className={styles.wrapper} id={id}>
        {(!hideIconOnMobile || !isMobile) && icon}

        <Typography variant="body2" component="div">
          {text}
        </Typography>
      </div>

      {subText && (
        <Box mb={4}>
          <Typography variant="body2" component="div">
            {subText}
          </Typography>
        </Box>
      )}
    </>
  );
};
