import { Action } from 'react-fetching-library';

import { DispatchMessagesRequest, DispatchMessagesResponse } from './DispatchMessages.types';

export const dispatchMessages = ({
  path: { account_id, location_id },
  query: { token },
  payload,
}: DispatchMessagesRequest): Action<DispatchMessagesResponse> => ({
  method: 'POST',
  endpoint: `/public/website/locations/${location_id}/accounts/${account_id}/dispatchmessage?token=${token}&XDEBUG_SESSION_START=1`,
  body: payload,
});
