import { useParameterizedQuery } from 'react-fetching-library';
import { useCallback } from 'react';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { updateUserAction } from 'api/actions/user/userActions';
import { UpdateUserRequest, UserResponse } from 'api/actions/user/userActions.types';
import { BasicUserAccount } from 'api/types/account';

export const useUpdateUser = () => {
  const { locationId, token, accountId } = useAuthState();
  const throwError = useAsyncError();
  const { query: updateUser } = useParameterizedQuery<UserResponse>(updateUserAction);

  return useCallback(
    async (user: Pick<BasicUserAccount, 'first_name' | 'last_name' | 'phone' | 'email'>) => {
      if (token && locationId && accountId) {
        const request: UpdateUserRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: { location_id: locationId, account_id: accountId },
          query: { token: token },
          payload: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            first_name: user.first_name?.trim(),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            last_name: user.last_name?.trim(),
            phone: user.phone.replace(/\D/g, ''),
            email: user.email?.trim(),
          },
        };
        const { error, payload } = await updateUser(request);
        if (!error && payload?.accounts[0]) {
          return true;
        } else {
          throwError(`Error on: UpdateUserRequest, ${payload?.meta?.status?.description}`);
        }
      }

      return undefined;
    },
    [accountId, locationId, throwError, token, updateUser],
  );
};
