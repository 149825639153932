import { colors } from '@chhjpackages/components';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';

export const useStyles = makeStyles(() => ({
  large: {
    width: 90,
    height: 63,
  },
}));

export const ToggleButtonStyled = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:focus': { background: `${colors.basic.black}` },
    },
    selected: {
      color: `${colors.basic.white} !important`,
      background: `${colors.primary.main} !important`,
      '&:focus': { background: `${colors.primary.dark}!important` },
    },
  }),
)(ToggleButton);
