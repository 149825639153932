import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from '@chhjpackages/components';

import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { AppRoute } from 'routing/AppRoute.enum';
import { RescheduleAppointmentProps } from 'app/rescheduleAppointment/RescheduleAppointment.types';

export const RescheduleAppointmentButton = ({ appointment }: { appointment: Appointment }) => {
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return (
      <Redirect
        to={{ pathname: AppRoute.reschedule, state: { appointment: appointment } as RescheduleAppointmentProps }}
      />
    );
  }

  return (
    <Button buttonType="twoTone" onClick={() => setRedirect(true)}>
      Reschedule Appointment
    </Button>
  );
};
