import { memo } from 'react';

import { TimeSlotSkeleton } from '../time-slot-button';

export const AvailabilityColumnSkeleton = memo(() => {
  return (
    <>
      <TimeSlotSkeleton />
      <TimeSlotSkeleton />
      <TimeSlotSkeleton />
    </>
  );
});
