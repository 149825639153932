import { FC, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { StylesProvider, createGenerateClassName } from '@material-ui/core';
import { AlertProvider, CollegeHunksTheme, DialogContextController, Loader } from '@chhjpackages/components';

import { ClientContextController } from 'context/client/clientContextController/ClientContextController';
import { AuthContextController } from 'context/auth/authContextController/AuthContextController';
import { AppointmentsContextController } from 'context/appointments/appointmentsContextController/AppointmentsContextController';
import { UserController } from 'context/auth/userController/UserController';
import { MessagesProvider } from 'hooks/useMessages/useMessages';
import { ErrorBoundary } from 'ui/errorBoundary/ErrorBoundary';
import { HunkPayContextController } from 'context/hunkPay/hunkPayContextController/HunkPayContextController';
import { AddressOptionsContextController } from 'context/addressOptions/addressOptionsContextController/AddressOptionsContextController';
import { LocationContextController } from 'context/location/locationContextController/LocationContextController';

const generateClassName = createGenerateClassName({
  productionPrefix: 'chhj-customer-portal',
});

export const AppProviders: FC = ({ children }) => (
  <StylesProvider generateClassName={generateClassName}>
    <CollegeHunksTheme>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ErrorBoundary>
          <AuthContextController>
            <ClientContextController>
              <Router>
                <UserController>
                  <LocationContextController>
                    <AppointmentsContextController>
                      <AddressOptionsContextController>
                        <HunkPayContextController>
                          <AlertProvider
                            maxSnack={3}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            preventDuplicate
                            autoHideDuration={null}
                          >
                            <DialogContextController>
                              <MessagesProvider>
                                <Suspense fallback={<Loader size="large" />}>{children}</Suspense>
                              </MessagesProvider>
                            </DialogContextController>
                          </AlertProvider>
                        </HunkPayContextController>
                      </AddressOptionsContextController>
                    </AppointmentsContextController>
                  </LocationContextController>
                </UserController>
              </Router>
            </ClientContextController>
          </AuthContextController>
        </ErrorBoundary>
      </MuiPickersUtilsProvider>
    </CollegeHunksTheme>
  </StylesProvider>
);
