import { memo } from 'react';
import { Typography } from '@material-ui/core';
import { Button } from '@chhjpackages/components';

import { useStyles } from './BillingInformationCard.styles';
import { BillingInformationCardProps } from './BillingInformationCard.types';

export const BillingInformationCard = memo(({ infoItems, action, hasError }: BillingInformationCardProps) => {
  const styles = useStyles({ hasError });

  return (
    <div className={styles.root}>
      <div>
        {infoItems.map((infoItem, index) => (
          <Typography key={index} variant="body1" className={styles.infoItem}>
            {infoItem}
          </Typography>
        ))}
      </div>
      <Button buttonType="text" color="secondary" size="small" onClick={action.onClick}>
        {action.title}
      </Button>
    </div>
  );
});
