import { Typography, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { memo } from 'react';
import clsx from 'clsx';

import { PriceTrucksProps } from './PriceTrucks.types';
import { useStyles } from './PriceTrucks.styles';

export const PriceTrucks = memo(({ priceList, isExpressJunk }: PriceTrucksProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles();

  const minimumTruckPriceId = !isExpressJunk ? 3 : 15634;
  const halfTruckPriceId = !isExpressJunk ? 20 : 15639;
  const fullTruckPriceId = !isExpressJunk ? 46 : 15644;
  const minimumTruckPrice = priceList.find(p => p.id === minimumTruckPriceId)?.price;
  const halfTruckPrice = priceList.find(p => p.id === halfTruckPriceId)?.price;
  const fullTruckPrice = priceList.find(p => p.id === fullTruckPriceId)?.price;

  return (
    <div className={styles.root}>
      <Grid container spacing={4} className={styles.trucksContainer}>
        <Grid item xs={12} md={4}>
          <div className={clsx(styles.truck, styles.minimumTruck)} />
          <Typography variant="subtitle2" color="textSecondary" className={styles.loadTitle}>
            Less than 1/8th truckload
          </Typography>
          {minimumTruckPrice ? (
            <Typography variant={isMobile ? 'h6' : 'h4'} color="primary">
              approx. ${minimumTruckPrice}
            </Typography>
          ) : (
            <Typography variant="body2" className={styles.notAvailable}>
              Price not available
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={clsx(styles.truck, styles.halfTruck)} />
          <Typography variant="subtitle2" color="textSecondary" className={styles.loadTitle}>
            1/2th truckload
          </Typography>
          {halfTruckPrice ? (
            <Typography variant={isMobile ? 'h6' : 'h4'} color="primary">
              approx. ${halfTruckPrice}
            </Typography>
          ) : (
            <Typography variant="body2" className={styles.notAvailable}>
              Price not available
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={clsx(styles.truck, styles.fullTruck)} />
          <Typography variant="subtitle2" color="textSecondary" className={styles.loadTitle}>
            Full truckload
          </Typography>
          {fullTruckPrice ? (
            <Typography variant={isMobile ? 'h6' : 'h4'} color="primary">
              approx. ${fullTruckPrice}
            </Typography>
          ) : (
            <Typography variant="body2" className={styles.notAvailable}>
              Price not available
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
});
