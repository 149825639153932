import { useState } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { Redirect } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';

import { AppointmentsResponse, UpdateAppointmentRequest } from 'api/actions/appointments/AppointmentsActions.types';
import { updateAppointment } from 'api/actions/appointments/AppointmentsActions';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { AppRoute } from 'routing/AppRoute.enum';
import { PageContent } from 'ui/page-content/PageContent';
import { displayDate } from 'utils/formatDate/FormatDate';
import { DashedSeparator } from 'ui/dashedSeparator/DashedSeparator';
import { AppointmentsTableContainer } from 'app/appointmentsTable/AppointmentsTableContainer';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';

import { useStyles } from './CancelAppointment.styles';
import { CancelAppointmentProps } from './CancelAppointment.types';
import { CancelAppointmentForm } from './CancelAppointmentForm/CancelAppointmentForm';

export function CancelAppointment({ appointment }: CancelAppointmentProps) {
  const styles = useStyles();
  const [success, setSuccess] = useState(false);
  const [reasonId, setReasonId] = useState<number>();
  const { accountId, locationId, token } = useAuthState();
  const setMessage = useMessagesDispatch();
  const { query, loading: isDuringRequest } = useParameterizedQuery<AppointmentsResponse>(updateAppointment);
  const throwError = useAsyncError();

  const handleSubmit = async (defaultReasonId?: number) => {
    if (defaultReasonId) {
      setReasonId(defaultReasonId);
    }
    if (appointment && reasonId && accountId && locationId && token) {
      const request: UpdateAppointmentRequest = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        path: { account_id: accountId, appointment_id: appointment.id, location_id: locationId },
        query: { token: token },
        payload: {
          account: { id: accountId },
          category: { id: appointment.category.id },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          start_date: format(appointment.startDate, 'yyyy-MM-dd HH:mm:ss'),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          end_date: format(appointment.endDate, 'yyyy-MM-dd HH:mm:ss'),
          origin: { id: appointment.origin.id },
          type: appointment.type,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          ...(appointment.type === 'EST' && { est_status: { id: 7 } }),
          ...(appointment.type !== 'EST' && { status: { id: 5 } }),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          lost_reason: { id: reasonId },
        },
      };
      const { error, payload } = await query(request);
      if (!error && payload?.appointments) {
        setSuccess(true);
      } else {
        throwError(`Error on: UpdateAppointmentRequest, ${payload?.meta?.status?.description}`);
      }
    }
  };

  if (reasonId) {
    handleSubmit(reasonId);
    setReasonId(0);
  } else if (success) {
    setMessage({ message: 'Appointment cancelled', type: 'success' });
    return <Redirect to={{ pathname: AppRoute.dashboard, state: { appointmentId: appointment.id } }} />;
  }

  return (
    <div data-testid="cancel" className={styles.root}>
      <PageContent
        title="Cancel appointment"
        subtitle={displayDate(appointment.startDate)}
        description={
          !reasonId
            ? 'Here you cancel your appointment'
            : 'Before we process your cancellation, check out what you may be missing out on'
        }
        appointmentId={appointment.id}
        closable
      >
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={7} lg={7} xl={7} className={styles.container}>
            <CancelAppointmentForm onCancel={handleSubmit} isDuringRequest={isDuringRequest} />
          </Grid>
        </Grid>
        <DashedSeparator />
        <AppointmentsTableContainer appointments={[appointment]} isLoading={false} isSingleAppointment />
      </PageContent>
    </div>
  );
}
