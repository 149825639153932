import { Button, USATimeFormatAmPm } from '@chhjpackages/components';
import { memo, useMemo } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { useTimeSlotStyles } from './styles';
import { TimeSlotProps } from './types';

export const TimeSlotButton = memo(({ timeSlot, isSelected, onSelect, width, disabled, duration }: TimeSlotProps) => {
  const styles = useTimeSlotStyles();

  const buttonText = useMemo(() => {
    let text = moment(timeSlot.schedule.start).format(USATimeFormatAmPm);

    if (duration) {
      text += ` - ${moment(timeSlot.schedule.start)
        .add(duration, 'hours')
        .format(USATimeFormatAmPm)}`;
    }

    return text;
  }, [duration, timeSlot.schedule.start]);

  return (
    <div className={styles.hourBox}>
      <Button
        buttonType="twoTone"
        size="large"
        fullWidth={!width}
        onClick={() => onSelect(timeSlot)}
        className={clsx(isSelected && styles.selected)}
        style={{ width }}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    </div>
  );
});
