import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

import { theme } from 'assets/theme/Theme';

export const useStyles = makeStyles({
  container: {
    marginTop: '5px',
    height: '292px',
    [theme.breakpoints.down('sm')]: {
      height: '320px',
    },
  },
  title: {
    textTransform: 'uppercase',
    padding: '15px 0 13px',
  },
  timeContainer: {
    overflow: 'auto',
  },
  timeButton: {
    padding: '2px 6px 0px 2px',
    margin: '0px 8px',
    border: '2px transparent solid',
    '& span': {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '200px',
      margin: 'auto',
    },
  },
  selected: {
    border: `2px dashed ${colors.primary.main}`,
  },
  noAvailability: {
    color: colors.grey60,
  },
});
