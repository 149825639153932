import { PDFDocument } from 'pdf-lib';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { Coordinates, Sign, UseSignPdfProps } from './useSignPdf.types';

export const useSignPdf = ({ width, currentSignaturePdfField, signatureFieldOffset, isLoading }: UseSignPdfProps) => {
  const [pdfDoc, setPdfDoc] = useState<PDFDocument>();
  const [blobPdf, setBlobPdf] = useState<Blob>();

  const { width: pdfWidth, height: pdfHeight, ref: pdfRef } = useResizeDetector();

  const [signs, setSigns] = useState<Sign[]>([]);
  const [pageNumberWithSignature, setPageNumberWithSignature] = useState<number>();
  const [pdfSignatureCoordinates, setPdfSignatureCoordinates] = useState({
    x: 0,
    y: 0,
  });
  const [signatureFieldCoordinates, setSignatureFieldCoordinates] = useState({
    x: 0,
    y: 0,
  });

  const countPages = useMemo(() => pdfDoc?.getPages().length ?? 0, [pdfDoc]);

  const savePdf = useCallback(async () => {
    if (!signs.length || !pdfDoc) {
      return;
    }

    const form = pdfDoc.getForm();
    const fields = form.getFields();

    fields.forEach(field => form.removeField(field));

    const pdfBytes = await pdfDoc.save();
    const newBlobPdf = new Blob([pdfBytes], { type: 'application/pdf' });

    return newBlobPdf;
  }, [pdfDoc, signs]);

  const getPdfDoc = useCallback(async (url: string) => {
    const existingPdf = await fetch(url).then(file => file.blob());

    const existingPdfBytes = await existingPdf.arrayBuffer();
    const pdfDocument = await PDFDocument.load(existingPdfBytes);
    const pdfDocumentBytes = await pdfDocument.save();

    setPdfDoc(pdfDocument);
    setBlobPdf(new Blob([pdfDocumentBytes], { type: 'application/pdf' }));
  }, []);

  const addSign = useCallback(
    async (
      image: {
        blob: Blob;
        imageHeight: number;
      },
      pageNumber: number,
      signFieldCoords: Coordinates & {
        width: { type: 'percent' | 'pixels'; value: number };
      },
    ) => {
      if (!pdfWidth || !pdfDoc || !pageNumber) {
        return;
      }

      const page = pdfDoc.getPages()[pageNumber - 1];

      const jpgImageBytes = await image.blob.arrayBuffer();
      const jpgImage = await pdfDoc.embedPng(jpgImageBytes);

      const imageHeight = image.imageHeight;
      const imageWidth = jpgImage.width / (jpgImage.height / imageHeight);
      const scale = page.getWidth() / pdfWidth;
      const signFieldScale =
        signFieldCoords.width.type === 'percent'
          ? signFieldCoords.width.value / 100
          : (signFieldCoords.width.value * scale * 100) / pdfWidth;

      const imageX = signFieldCoords.x * scale + (page.getWidth() * signFieldScale) / 2 - imageWidth / 2;
      const imageY = page.getHeight() - signFieldCoords.y * scale - imageHeight;

      const newSign = {
        pageNumber: pageNumber,
        signJpg: jpgImage,
        signBlob: image.blob,
        pdfDimensions: {
          x: imageX,
          y: imageY,
          height: imageHeight,
          width: imageWidth,
        },
        dimensions: {
          x: imageX / scale,
          y: imageY / scale,
          height: imageHeight / scale,
          width: imageWidth / scale,
        },
      };

      setSigns(prev => [...prev, newSign]);

      page.drawImage(jpgImage, newSign.pdfDimensions);
    },
    [pdfDoc, pdfWidth],
  );

  const renderSign = useCallback((sign: Sign) => {
    return (
      <img
        width={sign.dimensions.width}
        height={sign.dimensions.height}
        alt="Sign"
        src={URL.createObjectURL(sign.signBlob)}
        style={{
          position: 'absolute',
          bottom: sign.dimensions.y,
          left: sign.dimensions.x,
        }}
      />
    );
  }, []);

  useEffect(() => {
    if (!pdfWidth || isLoading) {
      return;
    }

    const scale = pdfWidth / width;
    const xOffset = !!signatureFieldOffset ? width * ((signatureFieldOffset.x * 100) / width / 100) : 0;
    const yOffset = !!signatureFieldOffset ? width * ((signatureFieldOffset.y * 100) / width / 100) : 0;

    setSignatureFieldCoordinates({
      x: pdfSignatureCoordinates.x * scale + xOffset * scale,
      y: pdfSignatureCoordinates.y * scale - yOffset * scale,
    });
  }, [isLoading, signatureFieldOffset, width, pdfWidth, pdfSignatureCoordinates]);

  useEffect(() => {
    if (!currentSignaturePdfField || isLoading || !pdfWidth) {
      return;
    }

    const intervalId = setInterval(() => {
      const allPdfElements = Array.from(document.getElementsByTagName('span'));
      const signatureElement = allPdfElements.find(span => span.innerText === currentSignaturePdfField);

      if (signatureElement) {
        const page = signatureElement.parentElement?.parentElement?.getAttribute('data-page-number');

        if (page) {
          setPageNumberWithSignature(Number(page));
        }

        const scale = width / pdfWidth;

        setPdfSignatureCoordinates({
          x: signatureElement.offsetLeft * scale,
          y: signatureElement.offsetTop * scale,
        });

        clearInterval(intervalId);
      }
    }, 10);
  }, [currentSignaturePdfField, isLoading, width, pdfWidth]);

  return {
    pdfRef,
    pdfDoc,
    pdfWidth,
    pdfHeight,
    blobPdf,
    signs,
    countPages,
    pageNumberWithSignature,
    pdfSignatureCoordinates,
    signatureFieldCoordinates,
    getPdfDoc,
    savePdf,
    addSign,
    renderSign,
  };
};
