import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from '@chhjpackages/components';

import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { AppRoute } from 'routing/AppRoute.enum';
import { CancelAppointmentProps } from 'app/cancelAppointment/CancelAppointment.types';

export const CancelAppointmentButton = ({ appointment }: { appointment: Appointment }) => {
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect to={{ pathname: AppRoute.cancel, state: { appointment } as CancelAppointmentProps }} />;
  }
  return (
    <Button buttonType="outlined" onClick={() => setRedirect(true)}>
      Cancel Appointment
    </Button>
  );
};
