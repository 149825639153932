import { Grid, Typography, Box, withStyles } from '@material-ui/core';
import { colors, useDialogDispatch } from '@chhjpackages/components';
import { memo } from 'react';

import Google from 'assets/images/google.png';
import Facebook from 'assets/images/facebook.png';
import Yelp from 'assets/images/yelp.png';
import Comment from 'assets/images/comment.png';
import { useReviewAppointmentPlatformClick } from 'hooks/useReviewAppointment/useReviewAppointmentPlatformClick';
import { ReviewAppointmentHappy } from '../reviewAppointmentHappy/ReviewAppointmentHappy';

import { ReviewAppointmentSocialProps } from './ReviewAppointmentSocial.types';
import { useStyles } from './ReviewAppointmentSocial.styles';

const GreyTextTypography = withStyles({
  root: {
    color: colors.basic.black,
  },
})(Typography);

export const ReviewAppointmentSocial = memo(({ appointment, reviewValue }: ReviewAppointmentSocialProps) => {
  const styles = useStyles();
  const reviewAppointmentPlatformClick = useReviewAppointmentPlatformClick();
  const setDialog = useDialogDispatch();

  const leaveTextReview = () => {
    setDialog({
      open: true,
      variant: 'schedule',
      dialogContent: <ReviewAppointmentHappy appointment={appointment} reviewValue={reviewValue} />,
    });
  };

  const sendPlatformClickData = (platform_id: number) => {
    reviewAppointmentPlatformClick(platform_id, appointment.id);
  };

  const handlePlatformClick = async (platform_page_id: number) => {
    const platform_page = appointment.reviewSettings.filter(item => item.id === platform_page_id)[0];
    sendPlatformClickData(platform_page.platform.id);
    window.open(platform_page.url);
    leaveTextReview();
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary" align="center">
          We are glad that you are satisfied with your service. thanks for your feedback! <br />
          <span className={styles.subtitle}>We’d love it if you shared on these platforms as well:</span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          {appointment.reviewSettings.map((item, index) => {
            const platformLogo =
              item.platform.name === 'Google'
                ? Google
                : item.platform.name === 'Facebook'
                ? Facebook
                : item.platform.name === 'Yelp'
                ? Yelp
                : '';

            return (
              <Grid item xs={12} key={index} onClick={() => handlePlatformClick(item.id)}>
                <Box className={styles.reviewPlatform}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item></Grid>
                    <Grid item className={styles.reviewPlatformItem}>
                      <img src={platformLogo} className={styles.socialLogo} alt={item.platform.name} />
                    </Grid>
                    <Grid item className={styles.reviewPlatformItem}>
                      <GreyTextTypography variant="h3">{item.platform.name}</GreyTextTypography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            );
          })}

          {(appointment.reviewSettings.length === 0 || appointment.reviewSettings[0].allow_text === true) && (
            <Grid item xs={12} key={10} onClick={() => leaveTextReview()}>
              <Box className={styles.reviewPlatform}>
                <Grid container direction="row" spacing={2}>
                  <Grid item></Grid>
                  <Grid item className={styles.reviewPlatformItem}>
                    <img src={Comment} className={styles.socialLogo} alt={'Leave a comment'} />
                  </Grid>
                  <Grid item className={styles.reviewPlatformItem}>
                    <GreyTextTypography variant="h3">... or share with us</GreyTextTypography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
});
