import { useContext } from 'react';

import { QuoteStateContext, QuoteDispatchContext } from 'context/quotes/quotesContext/QuotesContext';

export const useQuotesState = () => {
  const context = useContext(QuoteStateContext);

  if (context === undefined) {
    throw new Error('useQuotesState must be used within a QuotesContextController');
  }

  return context;
};

export const useQuotesDispatch = () => {
  const context = useContext(QuoteDispatchContext);

  if (context === undefined) {
    throw new Error('useQuotesDispatch must be used within a QuotesContextController');
  }

  return context;
};
