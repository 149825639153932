import { Grid, Typography } from '@material-ui/core';
import { memo, useEffect } from 'react';

import { SET_RELOAD_APPOINTMENTS } from 'context/appointments/appointmentsContextReducer/AppointmentsContextReducer.types';
import { useAppointmentsDispatch } from 'hooks/useAppointments/useAppointments';

import { ReviewAppointmentThankYouProps } from './ReviewAppointmentThankYou.types';

export const ReviewAppointmentThankYou = memo(({ reviewType }: ReviewAppointmentThankYouProps) => {
  const appointmentsDispatch = useAppointmentsDispatch();
  const message =
    reviewType === 'un-satisfied'
      ? 'Your information has been shared with our local team and we may reach out to you directly to discuss any concerns.'
      : 'We strive to achieve 100% customer satisfaction. We hope that you will call us next time you need any services.';

  useEffect(() => {
    appointmentsDispatch({ type: SET_RELOAD_APPOINTMENTS });
  }, [appointmentsDispatch]);

  return (
    <Grid container direction="row" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" color="secondary" align="center">
          Thank you for your feedback!
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" align="center">
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
});
